import type { DeepPartial, User } from "@directus/types";

export const useBoards = () => {
  const { useItems } = useCms();
  const { readItems, readItem, createItem, deleteItems, updateItem } =
    useItems();

  const { user } = useAuth();

  const getBoard = async (id: MaybeRef<string>): Promise<BoardItem> => {
    return await readItem("boards", unref(id), {
      fields: [
        "id",
        "title",
        "items.images_id.*.*",
        "items.posts_id.*.*",
        "owner",
      ],
    });
  };

  const getBoards = async (): Promise<BoardItem[]> => {
    return user.value?.id
      ? await readItems("boards", {
          filter: { owner: { id: { _eq: user.value.id } } },
          sort: ["title"],
          fields: ["id", "title", "items.images_id.*.*", "items.posts_id.*.*"],
        })
      : [];
  };

  const getBoardImage = async (
    board: BoardItem,
    image: ImageItem
  ): Promise<BoardImageItem[]> => {
    return await readItems("boards_images", {
      filter: {
        boards_id: { _eq: board?.id },
        images_id: { _eq: image?.id },
      },
      fields: ["id", "boards_id", "images_id", "posts_id"],
    });
  };

  const createBoard = async (board: MaybeRef<BoardItem>) => {
    return await createItem("boards", unref(board));
  };

  const hasBoardImage = async (board: BoardItem, image: ImageItem) => {
    return (await getBoardImage(board, image)).length > 0;
  };

  const addImageToBoard = async (
    board: BoardItem,
    image: ImageItem,
    post: PostItem
  ) => {
    return await createItem("boards_images", {
      boards_id: board.id,
      images_id: image.id,
      posts_id: post.id,
    } as BoardImageItem);
  };

  const removeImageFromBoard = async (board: BoardItem, image: ImageItem) => {
    const items = await getBoardImage(board, image);

    return await deleteItems(
      "boards_images",
      items.reduce<string[]>((acc, item) => {
        if (item.id) acc.push(item.id);
        return acc;
      }, [])
    );
  };

  const isImageOnAnyUserBoard = async (imageId: MaybeRef<string>) => {
    const u = user.value as User;
    if (!u) return false;

    const items = await readItems("boards_images", {
      filter: {
        images_id: { _eq: imageId },
        boards_id: { owner: { id: <any>u.id } },
      },
      fields: ["id"],
    }).catch(() => []);
    return items.length > 0;
  };

  const updateBoard = async (
    boardId: MaybeRef<string>,
    board: DeepPartial<BoardItem>
  ) => {
    return await updateItem(
      "boards",
      unref(boardId),
      unref(board) as BoardItem
    );
  };

  const deleteBoard = async (boardId: MaybeRef<string>) => {
    return await deleteItems("boards", [unref(boardId)]);
  };

  return {
    getBoard,
    getBoards,
    getBoardImage,
    createBoard,
    hasBoardImage,
    addImageToBoard,
    removeImageFromBoard,
    isImageOnAnyUserBoard,
    updateBoard,
    deleteBoard,
  };
};
